@import url('https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap');

body {
  margin: 0;
  font-family: 'Reenie Beanie', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url(/src/assets/roughtexture.png);
  color: #bebe6d;
}

