.App {
  text-align: center;
}

.container {
  padding-top: 100px;
  width: 80%;
  max-width: 1000px;
  margin: auto;
}
