.memo {
    display: flex;
    flex-direction:row;
    margin-top:35px;
    margin-bottom:35px;

}

.memodetails {
    width: 250px;
    padding-right: 35px;
    text-align: right;
    color:#FFFFFF;
}

.memocontents {
    width: 100%;
    text-align: left;
}